$primary: #027fa1;
$secondary: #009e94;

@import "bootstrap/scss/bootstrap.scss";

h1,
h2,
h5 {
  color: #009a93;
}

.page-item:last-child .page-link,
.page-item:first-child .page-link,
.form-check-input[type="checkbox"],
.badge,
.navbar-toggler,
.dropdown-menu,
.input-group-text,
.form-check,
.alert,
.form-select,
.btn,
.form-control,
.form-control-lg,
.card,
.card-header:first-child {
  border-radius: 0;
}

.card-header {
  background: #009e94; //007fa0;
  color: white;
}

.row {
  --bs-gutter-y: 1rem;
}

.bg-light {
  background-color: #ffffff !important;
}

.btn {
  margin-bottom: 5px;
}

.input-group .btn {
  margin-bottom: 0px;
}

.btn-primary {
  color: #ffffff;
}
.btn-primary:hover {
  color: #ffffff;
}

.btn-secondary {
  color: #ffffff;
}
.btn-secondary:hover {
  color: #ffffff;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: #027fa1;
  text-decoration: none;
}

/*.card-body a:hover {
  color: #fff;
  //text-decoration: none;
}*/

.fa-star {
  stroke: black;
  stroke-width: 50;
}
.footer {
  padding: 30px 30px 10px 30px;
  background-color: #009e94; //#027fa1;
  margin: 10px;
}
.footer h6 {
  color: #ffffff;
  font-weight: bold;
}

.footer hr {
  height: 2px;
  color: #ffffff;
}

.footer a {
  color: #ffffff;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.container {
  padding: 110px 10px;
}

.card {
  margin: 0 0 20px 0;
}

.card-title a {
  color: #009e94; //027fa1;
  //text-decoration: none;
}

.card-text a {
  color: #009e94; //027fa1;
  //text-decoration: none;
}

.card-text .badge {
  color: #fff;
}

.card-text .btn-primary {
  color: #fff;
}

.advert {
  border: 0.5px solid #cccccc;
  border-radius: 5px;
  padding: 5px;
  margin: 0px 0px 10px 0px;
}

.advert2 {
  padding: 5px;
  margin: 0px 0px 10px 0px;
}

.dl-horizontal dt {
  float: left;
  width: 160px;
  clear: left;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dl-horizontal dd {
  margin-left: 180px;
}
